import React from 'react';
import '../styles/notfound.css'; // Importando o arquivo de estilos

const NotFound = () => {
  return (
    <div className="notfound-container">
      <h1 className="notfound-title">404</h1>
      <p className="notfound-text">Página não encontrada</p>
      <a href="/" className="notfound-link">Voltar para a página inicial</a>
    </div>
  );
};

export default NotFound;
