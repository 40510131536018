import React from 'react';

import AparadorExpositor from '../assets/products/AparadorExpositor.jpg';
import AparadorParaCeramicos from '../assets/products/AparadorParaCeramicos.jpg';
import DisplayDeAmostras from '../assets/products/DisplayDeAmostras.jpg';
import DisplayDeCeramicos from '../assets/products/DisplayDeCeramicos.jpg';
//import DisplayDeMesa from '../assets/products/DisplayDeMesa.jpg';
import ExpositorCentralDeLoja from '../assets/products/ExpositorCentralDeLoja.jpg';
import ExpositorCeramicaPosted from '../assets/products/ExpositorCeramicaPosted.jpg';
import ExpositorDeAmostras2 from '../assets/products/ExpositorDeAmostras-2.jpg';
import ExpositorDeCeramicaEmMadeira from '../assets/products/ExpositorDeCeramicaEmMadeira.jpg';
import ExpositorDeCeramicaLacado from '../assets/products/ExpositorDeCeramicaLacado.jpg';
import ExpositorDeCeramicos2 from '../assets/products/ExpositorDeCeramicos-2.jpg';
import ExpositorDeCeramicos3 from '../assets/products/ExpositorDeCeramicos-3.jpg';
import ExpositorDeCeramicos4 from '../assets/products/ExpositorDeCeramicos-4.jpg';
import ExpositorDeCeramicos5 from '../assets/products/ExpositorDeCeramicos-5.jpg';
//import ExpositorDeCeramicos6 from '../assets/products/ExpositorDeCeramicos-6.jpg';
import ExpositorDeCeramicos7 from '../assets/products/ExpositorDeCeramicos-7.jpg';
import ExpositorDeCeramicos8 from '../assets/products/ExpositorDeCeramicos-8.jpg';
import ExpositorDeCeramicos9 from '../assets/products/ExpositorDeCeramicos-9.jpg';
import ExpositorDeCeramicos from '../assets/products/ExpositorDeCeramicos.jpg';
import ExpositorDeCeramicosComLogo from '../assets/products/ExpositorDeCeramicosComLogo.jpg';
import ExpositorDeChao from '../assets/products/ExpositorDeChao.jpg';
import ExpositorDeParede from '../assets/products/ExpositorDeParede.jpg';
import ExpositorDePiasETorneiras from '../assets/products/ExpositorDePiasETorneiras.jpg';
import ExpositorDeSanitas from '../assets/products/ExpositorDeSanitas.jpg';
import ExpositorDeShowrrom from '../assets/products/ExpositorDeShowrrom.jpg';
import ExpositoresVerticais from '../assets/products/ExpositoresVerticais.jpg';
import ExpositorTorneiras from '../assets/products/ExpositorTorneiras.jpg';
import ExpositorTorneirasEChuveiros from '../assets/products/ExpositorTorneirasEChuveiros.jpg';
import IlhaParaProdutoPiasESanitas from '../assets/products/IlhaParaProdutoPiasESanitas.jpg';
import IlhaParaProdutosPias2 from '../assets/products/IlhaParaProdutosPias-2.jpg';
import IlhaParaProdutosPias3 from '../assets/products/IlhaParaProdutosPias-3.jpg';
import IlhaParaProdutosPias from '../assets/products/IlhaParaProdutosPias.jpg';
import LojaDeTorneiras from '../assets/products/LojaDeTorneiras.jpg';
import MovelExpositor from '../assets/products/MovelExpositor.jpg';
import ParedeDeShowroom from '../assets/products/ParedeDeShowroom.jpg';
import ParedeExpositora from '../assets/products/ParedeExpositora.jpg';
import ParedeExpositoraComCaixaDeLuz from '../assets/products/ParedeExpositoraComCaixaDeLuz.jpg';
import ParedeParaAmostras from '../assets/products/ParedeParaAmostras.jpg';
import Showroom from '../assets/products/Showroom.jpg';
import ShowroomDeCeramicos from '../assets/products/ShowroomDeCeramicos.jpg';
import ShowroomDeSanitas from '../assets/products/ShowroomDeSanitas.jpg';







export const productImages = [
    { id: 1, src: AparadorExpositor, alt: 'Produto 1', title: 'Aparador Expositor' },
    { id: 2, src: AparadorParaCeramicos, alt: 'Produto 2', title: 'Aparador Para Cerâmicos' },
    { id: 3, src: DisplayDeAmostras, alt: 'Produto 3', title: 'Display de Amostras' },
    { id: 4, src: DisplayDeCeramicos, alt: 'Produto 5', title: 'Display de Cerâmicos' },
    //{ id: 5, src: DisplayDeMesa, alt: 'Produto 4', title: 'Display de Mesa' },
    { id: 6, src: ExpositorCentralDeLoja, alt: 'Produto 6', title: 'Expositor Central de Loja' },
    { id: 7, src: ExpositorCeramicaPosted, alt: 'Produto 7', title: 'Expositor v Posted' },
    { id: 8, src: ExpositorDeAmostras2, alt: 'Produto 8', title: 'Expositor de Amostras 2' },
    { id: 9, src: ExpositorDeCeramicaEmMadeira, alt: 'Produto 9', title: 'Expositor de Cerâmicos em Madeira' },
    { id: 10, src: ExpositorDeCeramicaLacado, alt: 'Produto 10', title: 'Expositor de Cerâmicos Lacado' },
    { id: 11, src: ExpositorDeCeramicos2, alt: 'Produto 11', title: 'Expositor de Cerâmicos 2' },
    { id: 12, src: ExpositorDeCeramicos3, alt: 'Produto 12', title: 'Expositor de Cerâmicos 3' },
    { id: 13, src: ExpositorDeCeramicos4, alt: 'Produto 13', title: 'Expositor de Cerâmicos 4' },
    { id: 14, src: ExpositorDeCeramicos5, alt: 'Produto 14', title: 'Expositor de Cerâmicos 5' },
    //{ id: 15, src: ExpositorDeCeramicos6, alt: 'Produto 15', title: 'Expositor de Cerâmicos 6' },
    { id: 16, src: ExpositorDeCeramicos7, alt: 'Produto 16', title: 'Expositor de Cerâmicos 7' },
    { id: 17, src: ExpositorDeCeramicos8, alt: 'Produto 17', title: 'Expositor de Cerâmicos 8' },
    { id: 18, src: ExpositorDeCeramicos9, alt: 'Produto 18', title: 'Expositor de Cerâmicos 9' },
    { id: 19, src: ExpositorDeCeramicos, alt: 'Produto 19', title: 'Expositor de Cerâmicos' },
    { id: 20, src: ExpositorDeCeramicosComLogo, alt: 'Produto 20', title: 'Expositor de Cerâmicos com Logo' },
    { id: 21, src: ExpositorDeChao, alt: 'Produto 21', title: 'Expositor de Chão' },
    { id: 22, src: ExpositorDeParede, alt: 'Produto 22', title: 'Expositor de Parede' },
    { id: 23, src: ExpositorDePiasETorneiras, alt: 'Produto 23', title: 'Expositor de Lavatórios e Torneiras' },
    { id: 24, src: ExpositorDeSanitas, alt: 'Produto 24', title: 'Expositor de Sanitas' },
    { id: 25, src: ExpositorDeShowrrom, alt: 'Produto 25', title: 'Expositor de Showrrom' },
    { id: 26, src: ExpositoresVerticais, alt: 'Produto 26', title: 'Expositores Verticais' },
    { id: 27, src: ExpositorTorneiras, alt: 'Produto 27', title: 'Expositor Torneiras' },
    { id: 28, src: ExpositorTorneirasEChuveiros, alt: 'Produto 28', title: 'Expositor Torneiras e Chuveiros' },
    { id: 29, src: IlhaParaProdutoPiasESanitas, alt: 'Produto 29', title: 'Ilha Para Produto Lavatórios e Sanitas' },
    { id: 30, src: IlhaParaProdutosPias2, alt: 'Produto 30', title: 'Ilha Para Produtos Lavatórios 2' },
    { id: 31, src: IlhaParaProdutosPias3, alt: 'Produto 31', title: 'Ilha Para Produtos Lavatórios 3' },
    { id: 32, src: IlhaParaProdutosPias, alt: 'Produto 32', title: 'Ilha Para Produtos Lavatórios' },
    { id: 33, src: LojaDeTorneiras, alt: 'Produto 33', title: 'Loja de Farmácia' },
    { id: 34, src: MovelExpositor, alt: 'Produto 34', title: 'Movel Expositor' },
    { id: 35, src: ParedeDeShowroom, alt: 'Produto 35', title: 'Parede de Showroom' },
    { id: 36, src: ParedeExpositora, alt: 'Produto 36', title: 'Parede Expositora' },
    { id: 37, src: ParedeExpositoraComCaixaDeLuz, alt: 'Produto 37', title: 'Parede Expositora Com Caixa de Luz'},
    { id: 38, src: ParedeParaAmostras, alt: 'Produto 38', title: 'Parede Para Amostras' },
    { id: 39, src: Showroom, alt: 'Produto 39', title: 'Showroom' },
    { id: 40, src: ShowroomDeCeramicos, alt: 'Produto 40', title: 'Showroom de Ceramicos' },
    { id: 41, src: ShowroomDeSanitas, alt: 'Produto 41', title: 'Showroom de Sanitas' },

  ];



const ProductImage = ({ src, alt, onClick }) => (
  <div onClick={onClick} style={{ cursor: 'pointer', margin: '10px' }}>
    <img src={src} alt={alt} title={String} style={{ width: '295px', height: 'auto', borderRadius: '5px' }} />
  </div>
);

export default ProductImage;