import React, { useState, useEffect } from "react";
import { NavLink } from 'react-router-dom';
import '../styles/home.css'
import Contatos from './Contacto';
import { productImages } from '../components/productImages';



import imgHome from '../assets/img/ImagenHome.jpeg';
import logoDominio from '../assets/img/LogoDomino.png';
import logoCeragni from '../assets/img/LogoRecer.png';
import logoCerteca from '../assets/img/LogoAleluia.png';
import logoPrimvs from '../assets/img/LogoPrimvs.png';
import triangleRedBG from '../assets/img/TrianguloRojoTransparencia.png';
import triangleWhiteBG from '../assets/img/TrianguloBlancoTransparencia.png';
import triangleGreenArrow from '../assets/img/flechaverde.png';
import triangleWhiteArrow from '../assets/img/flechablanca.png';
import circleExpositorTorneiras from '../assets/products/CircleExpositorTorneirasPk.jpg'
import expositorMg7760 from '../assets/products/circleExpositorMg7760.jpg'
import expositorSanitaFrente2 from '../assets/products/CircleExpositorSanitaFrente2.jpg'
import nextBtn from '../assets/img/nextBtn.png'
import img3 from '../assets/img/3.jpeg'
import blackArrow from '../assets/img/flechaPreta.png'

const redBtn = '#D0333A';



  const Home = () => {

    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [currentImageIndexFour, setCurrentImageIndexFour] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState();


    
    useEffect(() => {
      // Função para atualizar itemsPerPage com base na largura da tela
      const updateItemsPerPage = () => {
        if (window.innerWidth > 1024) {
          setItemsPerPage(4);
        } else if (window.innerWidth > 576) {
          setItemsPerPage(2);
        } else {
          setItemsPerPage(1);
        }
      };
  
      updateItemsPerPage(); // Chama a função uma vez ao montar
  
      // Adiciona o event listener para detectar mudanças na largura da tela
      window.addEventListener("resize", updateItemsPerPage);
  
      // Remove o event listener quando o componente for desmontado
      return () => window.removeEventListener("resize", updateItemsPerPage);
    }, []);
  



    const totalImages = productImages.length;
  

    const handlePreviousFour = () => {
      setCurrentImageIndexFour((prevIndex) =>
        prevIndex === 0 ? 0 : prevIndex - 1 // Para no início
      );
    };
    
    const handleNextFour = () => {
      setCurrentImageIndexFour((prevIndex) =>
        prevIndex === totalImages - itemsPerPage ? 0 : prevIndex + 1 // Volta ao início no final
      );
    };
  
    const visibleImagesFour = productImages.slice(
      currentImageIndexFour,
      currentImageIndexFour + itemsPerPage
    );

    // Cálculo do índice inicial para o grid
    const startIndex = Math.max(
      0,
      Math.min(currentImageIndex - 4, totalImages - 8)
    );
  
    // Imagens visíveis no grid
    const visibleImages = productImages.slice(startIndex, startIndex + 8);
  
    // Função para avançar no carrossel item unico
    const handleNext = () => {
      if (currentImageIndex < totalImages - 1) {
        setCurrentImageIndex((prev) => prev + 1);
      }
    };
  
    // Função para voltar no carrossel
    const handlePrevious = () => {
      if (currentImageIndex > 0) {
        setCurrentImageIndex((prev) => prev - 1);
      }
    };


   

    return (
      <div className='divHome' style={{ }}>
        
        <section id="ImgHomeSection">
          <div className="imgContainer">
            <img className='imgHome'  src={imgHome} alt='imgHome'/>
          </div>
        </section>

        <section id="TxtHomeSection">
          <div className="txtHomeContainer">
            <img className='greenArrow' src={triangleGreenArrow} alt='imgHome2'/>
            <img className='redTriangle'src={triangleRedBG} alt='imgHome2' />

            <NavLink 
              to='/contacto' 
              style={{ textDecoration: 'none' }}
              onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
            >
              <button className='btnLearnMore' style={{backgroundColor: redBtn, border: 'none', borderRadius: '5px', padding: '0px 20px', color: 'white'}}>
                Saiba mais
              </button>
            </NavLink>

            <h1 className="title">
              Exposmos o seu<br />
              produto, melhoramos<br />
              a sua imagem
            </h1>

            <p className="paragraph">
              A IMALUSIT - Indústria de Madeira Lusitana, Ltda é uma empresa com sede no Poreiro, a 2 km da cidade de Anadia, que
              desde 2007 se dedica exclusivamente à produção de displays (expositores) e showroom.
            </p>

            <p className="paragraph">
            Com excelente qualidade nos seus produtos, seja m construção ou acabamentos. cumrpimos rigorosmente os prazos
            estabelecidos nos projetos que se propõem.
            </p>

            <p className="paragraph">
              Os nossos produtos são pensados e fabricados de forma a teerem uma montagem de encaixe, simplificando o transporte e
              a colocação nos showrooms sem perder a principal função a visibilidade dos produtos expostos.
            </p>

            <p className="paragraph">
              Ouvimos, estudamos e aconselhamos os nossos clientes nas diversas vantagens que lhe proporcionem uma melhor 
              exposição e visibilidade.
            </p>

            <p className="paragraph">
            Reconhecemos o público como lideres, na qualidade, ética e empenho, nos serviços que prestamos.
            </p>

          </div>
        </section>

        <div className="circleBase circleGreen">
          <p style={{fontWeight: '400'}} >Produtos</p>
        </div>

        <section id="produtosSection">
            <div className='produtonContainer'>
              <div className="divP1">
                <div className="divP2">
                  <div className="divP3">
                    {/* Imagem central */}
                    <div className="imgP1">
                     <img className="btnP1" src={nextBtn} alt="nextBtn" onClick={handlePrevious}/>
        
                      <img className="imgP2"
                        src={productImages[currentImageIndex].src}
                        alt={productImages[currentImageIndex].alt}
                      /> 
                      <img className="btnP2" src={nextBtn} alt="nextBtn" onClick={handleNext} />
                    </div>

                    {/* Texto */}
                    <div className="textP2" >
                      <p className='titleSingleImage' >
                        {productImages[currentImageIndex].title}
                      </p>
                    </div>
                  </div>

                  {/* Grid de imagens */}
                  <div className="gridP1">
                    {visibleImages.map((image, index) => (
                      <img
                        className= {`imgGrid1 ${startIndex + index === currentImageIndex ? "active" : ""}`}
                        key={image.id}
                        src={image.src}
                        alt={image.alt}
                        onClick={() => setCurrentImageIndex(startIndex + index)}
                      />
                    ))}
                  </div>
                </div>
              </div> 
              
              <div className='divP4'>

                <div className='divP5' >
                    <div className='divP6'>
                      <img className='greenArrow arrowP6' src={triangleGreenArrow} alt='triangleGreenArrow'  />
                      <h3>
                        Os nossos produtos possuem montagem simples, facilitando o transporte e a instalação, sem comprometer a visibilidade dos itens expostos.  
                      </h3>
                    </div>

                    <div className="divP7"  >
                      <img className='expositorSanitaFrente2' src={expositorSanitaFrente2} alt='expositorTorneiras'  />
                      <h3>
                        Expositor Vertical para Cerâmicos<br/>   
                      </h3>
                    </div>

                    <div className="divP8">
                      <img className="circleExpositorTorneiras" src={circleExpositorTorneiras} alt="expositorTorneiras" />
                      <h3>
                        Expositor Baixo de Cerâmicos<br />
                      </h3>
                    </div>

                    <div className="divP9">
                      <img className="expositorMg7760" src={expositorMg7760} alt="expositorTorneiras" />
                      <h3>
                        Expositor de Mesa para Cerâmicos<br />
                      </h3>
                    </div>
                </div>
                </div>
          </div>
        </section>

        <section id="MaisVendidosSection">
        <div className="circleBase circleRed">
          <p className="pMVSection" >Nossos Clientes</p>
        </div>

          <div className="divSec1" >
            <img className='whiteArrow wAdivSec1' src={triangleWhiteArrow} alt='imgHome2'  />
            <img className='redTriangle rTdivSec1'src={triangleWhiteBG} alt='imgHome2' />
            <h1 className="white-title">
              Parceria Personalizada<br />
              para Máxima Visibilidade<br />
            </h1>

            <p className="white-paragraph">
              Trabalhamos em estreita colaboração com nossos clientes, ouvindo suas necessidades, estudando suas 
              exigencias e oferecendo aconcelhamentos especializado para garantir uma apresentação otimizada e com 
              máxima visibilidade para os produtos.
            </p>
          </div>
        </section>


    
      
        <section id="ClientLogoSection">
          <div className="divClientLogo1">
            <div className='rowCltLogo'>
              <img className="logoDominio" src={logoDominio} alt="logoDominio"  />
              <img className="logoCeragni" src={logoCeragni} alt="logoCeragni"  />
              <img className="logoCerteca" src={logoCerteca} alt="logoCerteca" />
              <img className="logoPrimvs" src={logoPrimvs} alt="logoPrimvs" />
            </div>
          </div>
        </section>


        <div className="divClientLogo2">
          <div className='txtImgBanho'>
            <div className='txtImgBanho1'>
              <h1 className="divClientLogo2H1">
                  Descubra e<br/>
                  crie seu espaço
              </h1>
              <img className= 'arrowDivc2' src={blackArrow} alt="blackArrow" />
              <p className="divClientLogo2P">
                Os nossos produtos possuem montagem simples, facilitando o transporte e a instalação, sem comprometer a visibilidade dos itens expostos. 
              </p>
            </div>
            <img className="imgBanho" src={img3} alt="imgBanho" />
          </div>

          <div className="carrocelProducts" >
            
            {/* Botão de Navegação para Esquerda */}
            <img className="imgCarrocel" src={nextBtn} alt="nextBtn" onClick={handlePreviousFour} />
              <div className="carrocelProducts1" >
                {/* Container do Carrossel */}
                <div className="carrocelGrid"
                >
                  {visibleImagesFour.map((image) => (
                    <div className="imgCarrocelProducts1"
                      key={image.id}

                    >
                      <img className="imgCarrocelProducts2"
                        src={image.src}
                        alt={image.alt}
                      />
                      <p className="imgCarrocelProducts2P" >
                        {image.title}
                      </p>
                    </div>
                ))}
              </div>
          </div>
          {/* Botão de Navegação para Direita */}
          <img className="imgCarrocel " src={nextBtn} alt="nextBtn" onClick={handleNextFour} style={{transform: 'rotate(180deg)', marginRight: '10px'}}/>
          </div>
        </div>
        <div className="circleBase circleGreen">
          <p  >Contacto</p>
        </div>
        

        <section id="contactSection">
          <Contatos />
        </section>

      </div>
    );
  }

export default Home;
