import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import logoImalusit from "../assets/img/logoImalusit.png";
import "../styles/Header.css"; // Importando o CSS separado

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <header className="headerContainer">
      <div className="headerContent">
        <a href="/" className="logoContainer">
          <img src={logoImalusit} alt="Logo" className="logo" />
        </a>

        {/* Botão de hambúrguer */}
        <button className="hamburger" onClick={() => setMenuOpen(!menuOpen)}>
          ☰
        </button>

        {/* Menu Responsivo */}
        <nav className={`navbarMenu ${menuOpen ? "active" : ""}`}>
          <ul className="navbarMenu-nav">
            <li className="navbarMenu-item">
              <NavLink to="/Home" className={({ isActive }) => `nav-link ${isActive ? "active" : ""}`} onClick={() => setMenuOpen(false)}>
                Home
              </NavLink>
            </li>
            <li className="navbarMenu-item">
              <NavLink to="/Contacto" className={({ isActive }) => `nav-link ${isActive ? "active" : ""}`} onClick={() => setMenuOpen(false)}>
                Contacto
              </NavLink>
            </li>
            <li className="navbarMenu-item">
              <NavLink to="/Produtos" className={({ isActive }) => `nav-link ${isActive ? "active" : ""}`} onClick={() => setMenuOpen(false)}>
                Produtos
              </NavLink>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
  