import React, { useState, useEffect } from 'react';
import { productImages } from '../components/productImages';
import '../styles/produtos.css';

const Produtos = () => {
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const handleClose = () => {
    setSelectedImage(null);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        handleClose();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, []);

  return (
    <div className="produtos-container">
      {/* Conteúdo com scroll */}
      <div className="produtos-content">
        <div className="produtos-grid">
          {productImages.map((image) => (
            <div key={image.id} className="produto-item" onClick={() => handleImageClick(image)}>
              <img src={image.src} alt={image.alt} />
              <p>{image.title}</p>
            </div>
          ))}
        </div>
      </div>
      {/* Modal de Imagem Selecionada */}
      {selectedImage && (
        <div className="produto-modal">
          <img src={selectedImage.src} alt="Imagem Selecionada" />
          <p>{selectedImage.title}</p>
          <button className="close-btn" onClick={handleClose}>X</button>
        </div>
      )}
    </div>
  );
};

export default Produtos;
