import React, { useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/footer.css"; // Importação do CSS separado

import iconeFace from "../assets/img/IconoFacebook.png";
import iconeInsta from "../assets/img/IconoInstagram.png";
import iconeLinkdin from "../assets/img/Linkedin.png";
import logoimaTel from "../assets/img/IconoTlf.png";
import iconeHome from "../assets/img/IconoHome.png";
import iconeEmail from "../assets/img/IconoCorreo.png";
import iconeWorld from "../assets/img/IconoUbicacion.png";
import logoimalusit from "../assets/img/LogoColor.png";

const Footer = () => {
    const location = useLocation();

    useEffect(() => {
        if (location.hash) {
            const targetId = location.hash.replace('#', '');
            const targetElement = document.getElementById(targetId);
            if (targetElement) {
                setTimeout(() => {
                    targetElement.scrollIntoView({ behavior: 'smooth' });
                }, 100);
            }
        }
    }, [location]);

    return (
        <div>
                        <div className="footer-top"></div>
            <div className="footer-container">
                <div className="footer-inner">
                    
                    <div className="row full-height">
                        
                        <div className="footer-content">
                            <div className="row align-items-center full-height">
                                
                                {/* Coluna Esquerda */}
                                
                                <div className="col left-column">
                                    <NavLink to="/home" className="footer-link">
                                        <p className="mb-1">Empresa</p>
                                    </NavLink>
                                    <NavLink to="/contacto" className="footer-link">
                                        <p className="mb-1">Contactos</p>
                                    </NavLink>
                                    <NavLink to="/home#MaisVendidosSection" className="footer-link">
                                        <p className="mb-1">Clientes</p>
                                    </NavLink>
                                </div>
                                

                                <div className="col right-column left">
                                    <div className="contact-info">
                                        <p className="mb-0">(+351) 231 522 776</p>
                                        <img src={logoimaTel} alt="Telefone" className="icon" />
                                    </div>
                                    <div className="contact-info">
                                        <p className="mb-0">R. da Carpintaria, 2</p>
                                        <img src={iconeHome} alt="Endereço" className="icon" />
                                    </div>
                                    <div className="contact-info">
                                        <p className="mb-0">geral@imalusit.pt</p>
                                        <img src={iconeEmail} alt="Email" className="icon" />
                                    </div>
                                    <div className="contact-info">
                                        <p className="mb-0">www.imalusit.pt</p>
                                        <img src={iconeWorld} alt="Website" className="icon" />
                                    </div>
                                </div>

                                {/* Coluna Central */}
                                <div className="col-md-auto text-center">
                                    <img src={logoimalusit} alt="Logo Imalusit" className="footer-logo" />
                                    <div className="social-icons">
                                        <a href="https://www.facebook.com/profile.php?id=61566259480831&locale" target="_blank" rel="noopener noreferrer">
                                            <img src={iconeFace} alt="Facebook" className="social-icon" />
                                        </a>
                                        <a href="https://www.linkedin.com/company/105134460" target="_blank" rel="noopener noreferrer">
                                            <img src={iconeLinkdin} alt="LinkedIn" className="social-icon" />
                                        </a>
                                        <a href="https://www.instagram.com/imalusit.pt" target="_blank" rel="noopener noreferrer">
                                            <img src={iconeInsta} alt="Instagram" className="social-icon" />
                                        </a>
                                        <p className="cprightTxt">2024 - Todos os direitos reservados.</p>
                                    </div>
                                    <p className="footer-text" style={{ marginTop: '16px' }}>Política de Privacidade:</p>
                                    <p className="footer-privacy">
                                        Os dados pessoais recolhidos, como nome, e-mail e número de telefone, serão utilizados exclusivamente para fins de contacto e não serão partilhados com terceiros.
                                    </p>
                                </div>

                                {/* Coluna Direita */}
                                <div className="col right-column right">
                                    <div className="contact-info">
                                        <p className="mb-0">(+351) 231 522 776</p>
                                        <img src={logoimaTel} alt="Telefone" className="icon" />
                                    </div>
                                    <div className="contact-info">
                                        <p className="mb-0">R. da Carpintaria, 2</p>
                                        <img src={iconeHome} alt="Endereço" className="icon" />
                                    </div>
                                    <div className="contact-info">
                                        <p className="mb-0">geral@imalusit.pt</p>
                                        <img src={iconeEmail} alt="Email" className="icon" />
                                    </div>
                                    <div className="contact-info">
                                        <p className="mb-0">www.imalusit.pt</p>
                                        <img src={iconeWorld} alt="Website" className="icon" />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Seção inferior do Footer */}
            <div className="footer-bottom"></div>
        </div>
    );
};

export default Footer;
