import React, { useState } from 'react';
import imgImalust from '../assets/img/ImalusitFoto.png';
import '../styles/contacto.css';

const Contacto = () => {
    const [loading, setLoading] = useState(false); // Estado para controlar o carregamento

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true); // Inicia o carregamento

        const formData = new FormData(event.target);

        try {
            const response = await fetch('https://www.imalusit.pt/scripts/sendEmail.php', {
                method: 'POST',
                body: formData,
            });

            const result = await response.json();
            alert(result.message);
        } catch (error) {
            console.error('Erro:', error);
            alert('Erro ao processar sua solicitação.');
        }

        setLoading(false); // Para o carregamento
        window.location.reload();
    };

    return (
        <div className="contacto-container">
            <div className="contacto-inner">
                <div className='form-container'>
                    <div className='contacto-content'>
                        <div className="contacto-form">
                            <h2>Contate-nos</h2>
                            <p className="contacto-text">
                                Ficaremos encantados em fazer parte do seu projeto.
                            </p>
                            <form className='form' onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <label htmlFor="nome">Nome</label>
                                    <input type="text" className="form-control" id="nome" name="nome" required />

                                    <label htmlFor="email">Email</label>
                                    <input type="email" className="form-control" id="email" name="email" required />

                                    <label htmlFor="telefone">Telefone</label>
                                    <input type="tel" className="form-control" id="telefone" name="telefone" required />

                                    <label htmlFor="mensagem">Assunto</label>
                                    <textarea className="form-control" id="mensagem" name="mensagem" rows="3" required></textarea>
                                </div>
                                <button type="submit" className="contacto-btn" disabled={loading}>
                                    {loading ? <span className="loader"></span> : "Enviar"}
                                </button>
                            </form>
                        </div>

                        <div className="contacto-image">
                            <img src={imgImalust} alt="Formulário" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contacto;
